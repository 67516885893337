import React, {Fragment, useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, Button } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import InputIcon from '@material-ui/icons/Input';



const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const {history} = useRouter();
  const classes = useStyles();
  const router = useRouter();
  // const session = useSelector(state => state.session);
  const [profile, setProfile] = useState(null);
  const roles = {'ROLE_TOKENIST': 'Tokenist', 'ROLE_STAFF': 'Staff', 'ROLE_ADMIN': 'Admin'};

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    if (localStorage.getItem('token_user')){
      setProfile(JSON.parse(localStorage.getItem('token_user')))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  if (!profile){
    return null;
  }

  const handleLogout = async () => {
    await  localStorage.removeItem('department_counter')
    await  localStorage.removeItem('token_user')
    await  localStorage.removeItem('current_call')
    history.push('/auth/login');
  };

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={profile.avatar}
          to="/profile"
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
          {profile.name}
        </Typography>
        <Typography variant="body2">
          {profile.roles[0] ? roles[profile.roles[0]]:''}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        { navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />

        ))}
      </nav>
      <Divider className={classes.divider} />
      <div
        style={{padding:0,margin:0,justifyContent:'center',marginVertical:'10'
        }}
      >
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={()=> handleLogout()}
        >
          <InputIcon className={classes.logoutIcon}/>
          Sign out
        </Button>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>

        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
